import React from 'react'
import about1 from '.././img/about-1.jpg'
import about2 from '.././img/about-2.jpg'

function About() {
    let projectName = process.env.REACT_APP_PROJECT_NAME
  return (
    <div name="about" class="container-fluid py-5 my-5">
        <div class="container pt-5">
            <div class="row g-5">
                <div class="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                    <div class="h-100 position-relative">
                        <img src={about1} class="img-fluid w-75 rounded" alt="" style={{marginBottom: "25%"}}/>
                        <div class="position-absolute w-75" style={{top: "25%", left: "25%"}}>
                            <img src={about2} class="img-fluid w-100 rounded" alt=""/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                    <h5 class="text-primary">About Us</h5>
                    <h1 class="mb-4">About {projectName} Agency And It's Innovative IT Solutions</h1>
                    <p>Welcome to {projectName}, where innovation meets expertise. we are a dynamic IT company committed to delivering exceptional solutions that drive business growth. Our journey began with 
                    a vision to transform industries through technology, and today, we stand as a beacon of innovation.</p>
                    
                    <p class="mb-4">Collaboration is at the heart of our approach. We work closely with our clients, fostering strong partnerships to understand their challenges and aspirations. This 
                    collaborative spirit enables us to develop custom solutions that not only address immediate needs but 
                    also pave the way for sustained success in a rapidly evolving digital landscape.
                    </p>

                    {/* <a href="" class="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</a> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default About