import React from 'react'

function contact() {
   
    let projectPhoneNumber = process.env.REACT_APP_PROJECT_PHONE_NO
    let projectEmail = process.env.REACT_APP_PROJECT_EMAIL
    let projectAddress = process.env.REACT_APP_PROJECT_ADDRESS
  return (
    <div name="contact" class="container-fluid py-5 mb-5">
        <div class="container">
            <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                <h5 class="text-primary">Get In Touch</h5>
                <h1 class="mb-3">Contact for any query</h1>
                <p class="mb-2">Connecting with us is the first step toward unlocking tailored solutions for your business. Whether you have questions, 
                    ideas, or collaboration inquiries, our Contact Us page serves as a direct gateway to our responsive team.
                </p>
            </div>
            <div class="contact-detail position-relative p-5">
                <div class="row g-5 mb-5 justify-content-center">
                    <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                        <div class="d-flex bg-light p-3 rounded">
                            <div class="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: "64px", height: "64px"}}>
                                <i class="fas fa-map-marker-alt text-white"></i>
                            </div>
                            <div class="ms-3">
                                <h4 class="text-primary">Address</h4>
                                <a href="" target="_blank" class="h5">{projectAddress}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                        <div class="d-flex bg-light p-3 rounded">
                            <div class="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: "64px", height: "64px"}}>
                                <i class="fa fa-phone text-white"></i>
                            </div>
                            <div class="ms-3">
                                <h4 class="text-primary">Call Us</h4>
                                <a class="h5" href={`tel:${projectPhoneNumber}`} target="_blank">{projectPhoneNumber}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                        <div class="d-flex bg-light p-3 rounded">
                            <div class="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: "64px", height: "64px"}}>
                                <i class="fa fa-envelope text-white"></i>
                            </div>
                            <div class="ms-3">
                                <h4 class="text-primary">Email Us</h4>
                                <a class="h5" href={`mailto:${projectEmail}`} target="_blank">{projectEmail}</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default contact