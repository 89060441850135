import React from 'react'
import {Link} from 'react-scroll';

function Footer() {
    let projectName = process.env.REACT_APP_PROJECT_NAME
    let projectFirstName = projectName.split(" ")[0];
    let projectSecondName = projectName.split(" ")[1];
    let projectPhoneNumber = process.env.REACT_APP_PROJECT_PHONE_NO
    let projectEmail = process.env.REACT_APP_PROJECT_EMAIL
    let projectAddress = process.env.REACT_APP_PROJECT_ADDRESS
  return (
    <div class="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
        <div class="container pt-5 pb-4">
            <div class="row g-5">
                <div class="col-lg-3 col-md-6">
                    <a href="index.html">
                        <h1 class="text-white fw-bold d-block">{projectFirstName} <span class="text-secondary">{projectSecondName}</span> </h1>
                    </a>
                    <p class="mt-4 text-light">We're not just building solutions; we're crafting digital experiences that empower businesses and transform industries. 
                        Join us on a journey of innovation, collaboration, and unparalleled expertise.
                         <hr  />
                         Properietor Sujit Singh
                    </p>
                    <div class="d-flex hightech-link">
                        {/* <a href="" class="btn-light nav-fill btn btn-square rounded-circle me-2"><i class="fab fa-facebook-f text-primary"></i></a>
                        <a href="" class="btn-light nav-fill btn btn-square rounded-circle me-2"><i class="fab fa-twitter text-primary"></i></a>
                        <a href="" class="btn-light nav-fill btn btn-square rounded-circle me-2"><i class="fab fa-instagram text-primary"></i></a>
                        <a href="" class="btn-light nav-fill btn btn-square rounded-circle me-0"><i class="fab fa-linkedin-in text-primary"></i></a> */}
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <a href="#" class="h3 text-secondary">Short Link</a>
                    <div class="mt-4 d-flex flex-column short-link">
                        <p style={{cursor:"pointer"}} class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>
                            <Link to="home"  smooth={true}  duration={500}>Home</Link>
                        </p>
                        <p style={{cursor:"pointer"}} class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>
                            <Link to="about"  smooth={true}  duration={500}> About us </Link>
                        </p>
                        <p style={{cursor:"pointer"}} class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>
                            <Link to="service"  smooth={true}  duration={500}>Our Services</Link>
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <a href="#" class="h3 text-secondary">Help Link</a>
                    <div class="mt-4 d-flex flex-column help-link">
                        <a href="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Terms Of use</a>
                        <a href="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Privacy Policy</a>
                        <a href="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Helps</a>
                        <a href="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>FQAs</a>
                        <a href="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Contact</a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <a href="#" class="h3 text-secondary">Contact Us</a>
                    <div class="text-white mt-4 d-flex flex-column contact-link">
                        <a href="#" class="pb-3 text-light border-bottom border-primary"><i class="fas fa-map-marker-alt text-secondary me-2"></i> {projectAddress}</a>
                        <a href="#" class="py-3 text-light border-bottom border-primary"><i class="fas fa-phone-alt text-secondary me-2"></i> {projectPhoneNumber}</a>
                        <a href="#" class="py-3 text-light border-bottom border-primary"><i class="fas fa-envelope text-secondary me-2"></i> {projectEmail}</a>
                    </div>
                </div>
            </div>
            {/* <hr class="text-light mt-5 mb-4"> */}
            <div class="row">
                <div class="col-md-6 text-center text-md-start">
                    <span class="text-light"><a href="#" class="text-secondary"><i class="fas fa-copyright text-secondary me-2"></i>{projectName}</a>, All right reserved.</span>
                </div>
                <div class="col-md-6 text-center text-md-end">

                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
