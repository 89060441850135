import "./css/App.css"

import Topbar from "./components/Topbar";
import Navbar from "./components/Navbar";
import Fact from "./components/Fact";
import About from "./components/About";
import Carousel from "./components/carousel/Carousel";
import Service from "./components/services/Service"
import Contact from "./components/Contact"
import Footer from "./components/Footer";


function App() {
    return ( < div className = "App" >
        {/* <Topbar/> */}
        <Navbar/>
        <Carousel/>
        <Fact/>
        <About/>
        <Service/>
        <Contact/>
        <Footer/>



        </div>
    );
}

export default App;