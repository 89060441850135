import React from 'react'
import {Link} from 'react-scroll';

function Navbar() {
    let projectName = process.env.REACT_APP_PROJECT_NAME
    let projectFirstName = projectName.split(" ")[0];
    let projectSecondName = projectName.split(" ")[1];

    let projectPhoneNumber = process.env.REACT_APP_PROJECT_PHONE_NO
    
  return (
    <div class="container-fluid bg-primary">
        <div class="container">
            <nav class="navbar navbar-dark navbar-expand-lg py-0">
                <a href="index.html" class="navbar-brand">
                    <h1 class="text-white fw-bold d-block">{projectFirstName} <span class="text-secondary">{projectSecondName}</span> </h1>
                </a>
                <button type="button" class="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                <div class="collapse navbar-collapse bg-transparent" id="navbarCollapse">
                    <div class="navbar-nav ms-auto mx-xl-auto p-0">
                        <p style={{cursor:"pointer"}} class="nav-item nav-link active text-secondary">
                            <Link to="home"  smooth={true}  duration={300}>Home</Link>
                        </p>
                        <p style={{cursor:"pointer"}} class="nav-item nav-link">
                            <Link to="about"  smooth={true}  duration={300}>About</Link>
                        </p>
                        <p style={{cursor:"pointer"}} class="nav-item nav-link">
                            <Link to="service"  smooth={true}  duration={300}>Services</Link>
                        </p>
                        
                        <p style={{cursor:"pointer"}} class="nav-item nav-link">
                            <Link to="contact"  smooth={true}  duration={300}>Contact</Link>
                        </p>
                        {/* commented code that can be used later */}
                        {/* <a href="project.html" class="nav-item nav-link">Projects</a>
                        <div class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                            <div class="dropdown-menu rounded">
                                <a href="blog.html" class="dropdown-item">Our Blog</a>
                                <a href="team.html" class="dropdown-item">Our Team</a>
                                <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                                <a href="404.html" class="dropdown-item">404 Page</a>
                            </div>
                        </div> */}

                    </div>
                </div>
                <div class="d-none d-xl-flex flex-shirink-0">
                    <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4">
                        <a href="" class="position-relative animated tada infinite">
                            <i class="fa fa-phone-alt text-white fa-2x"></i>
                            <div class="position-absolute" style={{top: '-7px', left: '20px'}}>
                                <span><i class="fa fa-comment-dots text-secondary"></i></span>
                            </div>
                        </a>
                    </div>
                    <div class="d-flex flex-column pe-4 ">
                        <span class="text-white-50">Have any questions?</span>
                        <span class="text-secondary">Call: {projectPhoneNumber}</span>
                    </div>
                    {/* <div class="d-flex align-items-center justify-content-center ms-4 ">
                        <a href="#"><i class="bi bi-search text-white fa-2x"></i> </a>
                    </div> */}
                </div>
            </nav>
        </div>
    </div>
  )
}

export default Navbar