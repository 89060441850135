import React from 'react'
import ServicesItem
 from './ServicesItem'
function Service() {
  return (
    <div name="service" class="container-fluid services py-5 mb-5">
        <div class="container">
            <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                <h5 class="text-primary">Our Services</h5>
                <h1>Services Built Specifically For Your Business</h1>
            </div>
            <div class="row g-5 services-inner">
                <ServicesItem classes="fa fa-code fa-7x mb-4 text-primary" name="Web design" description="Elevate your online presence with visually stunning and user-friendly websites. Our web design services blend creativity and functionality for an impactful digital experience."/>
                <ServicesItem classes="fa fa-file-code fa-7x mb-4 text-primary" name="Web Development" description="Transform your ideas into robust web solutions. Our web development services leverage cutting-edge technologies to build scalable and high-performance websites tailored to your unique needs."/>
                <ServicesItem classes="fa fa-user-secret fa-7x mb-4 text-primary" name="Cyber Security" description="Safeguard your digital assets with our comprehensive cybersecurity solutions. We offer robust measures to protect against cyber threats, ensuring the integrity and confidentiality of your data."/>
                <ServicesItem classes="fa fa-envelope-open fa-7x mb-4 text-primary" name="Digital Marketing" description="Boost your online visibility and engage your audience effectively. Our digital marketing services encompass strategic planning, SEO, social media, and targeted campaigns for measurable business growth."/>
                <ServicesItem classes="fa fa-laptop fa-7x mb-4 text-primary" name="Cloud Computing" description="Embrace the power of the cloud for scalable and efficient operations. Our cloud computing solutions optimize resources, enhance collaboration, and ensure the agility needed for your business to thrive in the digital era."/>
                <ServicesItem classes="fa fa-external-link-alt fa-7x mb-4 text-primary" name="App development" description="Crafting intuitive and high-performance mobile applications tailored to your business needs. Our app development services encompass native and cross-platform solutions, ensuring seamless user experiences and functionality across diverse devices"/>
            </div>
        </div>
    </div>
  )
}

export default Service