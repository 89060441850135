import React from 'react'

function ServicesItem(props) {
  return (
    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
        <div className="services-item bg-light">
            <div className="p-4 text-center services-content">
                <div className="services-content-icon">
                    <i className={props.classes} ></i>
                    <h4 className="mb-3">{props.name}</h4>
                    <p className="mb-4">{props.description}</p>
                    {/* <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesItem