import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css'
import App from './App';
import './css/bootstrap.min.css'
import './css/style.css'
import './css/animate/animate.min.css'
import './css/owlcarousel/assets/owl.carousel.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( < React.StrictMode >
    <App />
    </React.StrictMode>
);