import React from 'react'
import carouselImage from '../../img/carousel-1.jpg'

function CarouselItem() {
  return (
    <div class="carousel-item active">
        <img src={carouselImage} class="img-fluid" alt="First slide"/>
        <div class="carousel-caption">
            <div class="container carousel-content">
                <h6 class="text-secondary h4 animated fadeInUp">Best IT Solutions</h6>
                <h1 class="text-white display-1 mb-4 animated fadeInRight">An Innovative IT Solutions Agency</h1>
                <p class="mb-4 text-white fs-5 animated fadeInDown">Empower your business with cutting-edge IT solutions. We provide expert services in software development, cybersecurity, Web development, and more.</p>
                {/* <a href="" class="me-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a> */}
                {/* <a href="" class="ms-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a> */}
            </div>
        </div>
    </div>
  )
}

export default CarouselItem